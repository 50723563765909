import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import ApiService from "../api/ApiService";
//import "../css/brithdaySlot.css";
import { Modal, ModalTitle } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";

import notification from "../helpers/notification";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import CalendarSlot from "./CalendarForSlotBook.jsx"
import PageHeader from '../components/common/PageHeader'


const BrithdaySlot = () => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOpenn, setIsDialogOpenn] = useState(false);


    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [calendar, setCalendar] = useState([]);
    const [selectedBrithdays, setSelectedSerivce] = useState(null);
    const [bookedSlots, setBookedSlots] = useState(null);
    // console.log("bookedSlots", bookedSlots);


    const login_check = localStorage.getItem(`birthday_token`);
    if (!login_check) {
        navigate('/birthday_login')
        notification("error", "Please login now !");
    }


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const generateCalendar = (month) => {
        const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
        const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        const calendar = [];

        for (let d = new Date(startOfMonth); d <= endOfMonth; d.setDate(d.getDate() + 1)) {
            calendar.push(new Date(d));
        }

        return calendar;
    };
    const fetchAvailableTimeSlots = (date, slot) => {
        // console.log(date, slot, "date, slot");
        const dayOfWeek = date.getDay();
        // console.log(dayOfWeek, "dayOfWeek");

        if (slot === 'morning' && dayOfWeek === 0) {

            return [];
        } else if (slot === 'evening' && (dayOfWeek === 0 || dayOfWeek === 6)) {

            return [];
        } else if (slot === 'morning') {
            return [
                '10:30AM-10:45AM',
                '10:45AM-11:00AM',
                '11:00AM-11:15AM',
                '11:15AM-11:30AM',
            ];
        } else if (slot === 'evening') {
            return [
                '5:00PM-5:15PM',
                '5:15PM-5:30PM',
                '5:30PM-5:45PM',
            ];
        } else {
            return [];
        }
    };


    const fetchBookedSlots = async () => {
        try {
            const formattedDate = moment(selectedDate).format('DD-MM-YYYY');
            const response = await ApiService.fetchBookedSlots(formattedDate, selectedTime);
            // console.log("booked slot", response)
            const numberOfBookedSlots = response.bookedSlot.length;
            if (numberOfBookedSlots >= 3) {

                setSelectedTime(null);
            }
            setBookedSlots(response.bookedSlot.length);
        } catch (error) {
            console.error("Error fetching booked slots:", error);
        }
    };


    const handleDateSelection = date => {
        setSelectedDate(date);
        setSelectedSlot(null);
        setSelectedTime(null);
    };

    const handleSlotSelection = slot => {
        setSelectedSlot(slot);
        setSelectedTime(null);
        setIsDialogOpenn(true);
    };
    const handleTimeSelection = time => {
        setSelectedTime(time);
        setIsDialogOpen(true);
        setIsDialogOpenn(false);
        // Placeholder function for handling booking
        // Replace this with your actual booking API call

        // ApiService.bookTimeSlot(selectedDate, selectedSlot, time)
        //     .then(response => {
        //         // Handle successful booking
        //         console.log("Booking successful:", response);
        //     })
        //     .catch(error => {
        //         // Handle booking error
        //         console.error("Booking error:", error);
        //     });
    };
    const handleMonthChange = direction => {
        const newMonth = new Date(currentMonth);
        if (direction === "next") {
            newMonth.setMonth(newMonth.getMonth() + 1);
        } else {
            newMonth.setMonth(newMonth.getMonth() - 1);
        }
        setCurrentMonth(newMonth);
        setCalendar(generateCalendar(newMonth));
        // console.log("newMonths", newMonth)
    };




    const handleBrithdaySave = async (data) => {
        try {
            //Send Whatsapp Message Here For Slot Booking
            const res = await ApiService.brithdaySlotBook(data);
            notification("success", "Hare Krishna! Your Slot has been Booked.");
            reset();
        } catch (err) {
            console.log(err);
            notification("error", err.response.data.message);
        }
        setIsDialogOpen(false);
        setIsDialogOpenn(false);
    };

    const handleViewButtonClick = async () => {

        navigate('/view_slot');
    };



    const closeDialog = () => {
        setBookedSlots(0);
        setIsDialogOpen(false);
        setIsDialogOpenn(true);
    };


    const closeDialogg = () => {

        setIsDialogOpenn(false);
    };

    useEffect(() => {
        if (selectedDate && selectedTime) {
            fetchBookedSlots();
        }
    }, [selectedDate, selectedTime]);
    useEffect(() => {
        setCalendar(generateCalendar(currentMonth));
    }, [currentMonth]);

    return (
        <>
            <PageHeader
                title={"Birthday Slot Booking"}
                imgSrc={
                    "/images/common/birthday_slot_banner.jpeg"
                }
            />
            <section className="py-5 kartik-deepdaan-calendar campaign-heading-sec">
                <div className="container mt-5">
                    <div className="row mb-5 birthday_img" >
                        <div className="col-lg-10 mx-auto">
                            <figure className="mb-0">
                                <img
                                className="w-100"
                                    src="/images/campaign/birthday_img.webp"
                                    alt=""
                                />
                            </figure>
                        </div>
                    </div>

                    <div className="row">
                        {/* <div className="calendar-header">
                            {currentMonth > new Date() && (
                                <button className=" box-hover text-white" onClick={() => handleMonthChange("previous")}><svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M308.2 530.5L689 858.9c14.2 12.2 35 1.2 35-18.5V183.6c0-19.7-20.8-30.7-35-18.5L308.2 493.1c-10.9 9.4-10.9 27.6 0 37z"
                                    ></path>
                                </svg>
                                </button>
                            )}
                            <div className="month-container">
                                <h2 className="p-5">{currentMonth.toLocaleString("default", { month: "long", year: "numeric" })}</h2>
                                <button className=" box-hover text-white" onClick={() => handleMonthChange("next")}> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"></path></svg></button>
                            </div>
                            <button className=" box-hover text-white" style={{ marginLeft: '5px' }} onClick={handleViewButtonClick}>Action</button>
                        </div> */}

                        <CalendarSlot />

                        {/* 
                        <div className="grid-container-days d-none d-lg-grid">
                            {calendar.slice(0, 7).map(date => (
                                <div key={date} className={`col grid-item-days ${date.getDay() === 0 ? 'sunday' : ''}`} style={{ color: date.getDay() === 0 ? 'red' : 'black', fontWeight: 'bold' }}>
                                    {date.toLocaleDateString('en-US', { weekday: 'long' })}
                                </div>
                            ))}
                        </div> */}


                        {/* <div className="calendar-container calendar grid-container">
                            {calendar.map(date => (
                                <div
                                    key={date}
                                    className={`calendar-date ${selectedDate === date ? "selected" : ""} ${date.getDay() === 0 ? "sunday" : ""}`}
                                >
                                    {selectedDate === date ? (
                                        <>
                                            <div className="slot-buttons">
                                                <button onClick={() => handleSlotSelection('morning')}>
                                                    Morning Slot
                                                </button>
                                                <button onClick={() => handleSlotSelection('evening')}>
                                                    Evening Slot
                                                </button>
                                            </div>

                                        </>
                                    ) : (
                                        <div onClick={() => handleDateSelection(date)}>
                                            <span className={`date-label ${date.getDay() === 0 ? "sunday" : ""}`}>
                                                {date.getDate().toString().padStart(2, '0')} {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}

                                                {date.getDay() === 0 && <span className="holiday-label"> Holiday</span>}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div> */}

                    </div>
                </div>
            </section>







            {/* <Modal centered show={isDialogOpen}>
                <div className="modal-wrap">
                    <button className="btn close-svg" onClick={closeDialog}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                            ></path>
                        </svg>
                    </button>
                    <div className="row">
                       
                        <div className="col-md-12">
                            <div className="pop-up-form">

                                <div className="pop-up-top">
                                    <figure className="hare-krishna-logo-fig">
                                        <img src={`/images/campaign/feedbackimg.jpeg`} alt="" />
                                    </figure>
                                    <h3>Birthday Puja Booking Form</h3>

                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>
                                </div>
                                {bookedSlots === 3 ? 'Already Booked' :
                                    <form
                                        action=""
                                        className="mt-3 mt-sm-0"
                                        onSubmit={handleSubmit(handleBrithdaySave)}
                                    >

                                        <input
                                            type="hidden"
                                            id="selectedDate"
                                            {...register("selectedDate")}
                                            value={selectedDate ? moment(selectedDate).format("DD-MM-YYYY") : ""}
                                        />

                                        <input
                                            type="hidden"
                                            id="selectedTime"
                                            {...register("selectedTime")}
                                            value={selectedTime || ""}
                                        />

                                        <input
                                            type="hidden"
                                            id="poojastatus"
                                            {...register("poojastatus")}
                                            value="Booked"
                                        />

                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="donorname" className="imp">Donor Name</label>
                                                    <input type="text"
                                                        id="donorname"
                                                        {...register("donorname", { required: true })}
                                                        onKeyPress={(event) => {
                                                            if (/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    {errors.donorname?.type === "required" && (
                                                        <span>  Name is required </span>
                                                    )}

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="phone" className="imp">Donor Mobile Number</label>
                                                    <input
                                                        type="tel"
                                                        id="phone"
                                                        maxLength={10}

                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register("phone", {
                                                            required: true,
                                                            pattern: {
                                                                value: /^\d+$/,
                                                                message: "Mobile number must be number.",
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: "Phone number must be minimum 10 digit",
                                                            },
                                                        })}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="iscid" className="imp">Donor ICS ID</label>
                                                    <input type="text"
                                                        id="icsid"
                                                        {...register("icsid")}
                                                    />
                                                    {errors.icsid?.type === "required" && (
                                                        <span>  ICS Id is required </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="whoisbook" className="imp">Booking Team</label>
                                                    <select type="text"
                                                        id="whoisbook" {...register("whoisbook")}>
                                                        <option value="Bhisma Team">Bhisma Team</option>
                                                        <option value="Cultivator">Cultivator</option>
                                                    </select>
                                                    {errors.whoisbook?.type === "required" && (
                                                        <span> This is required </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="bookername" className="imp">Booked By</label>
                                                    <input type="text"
                                                        id="bookername"
                                                        {...register("bookername", { required: true })}
                                                        onKeyPress={(event) => {
                                                            if (/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    {errors.bookername?.type === "required" && (
                                                        <span>Booker  Name is required </span>
                                                    )}

                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="bookerphone" className="imp">Mobile Number</label>
                                                    <input type="tel"
                                                        id="bookerphone"
                                                        maxLength={10}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}

                                                        {...register("bookerphone", {
                                                            required: true,
                                                            pattern: {
                                                                value: /^\d+$/,
                                                                message: "Mobile number must be number.",
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: "Phone number must be minimum 10 digit",
                                                            },
                                                        })}
                                                    />

                                                </div>
                                            </div>

                                            <button className="box-hover custom-btn" type="submit">Submit Slot</button>

                                        </div>
                                    </form>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal centered show={isDialogOpenn}>
                <div className="modal-wrap">
                    <button className="btn close-svg" onClick={closeDialogg}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                            ></path>
                        </svg>
                    </button>
                    <div className="row">
                      
                        <div className="col-md-12">
                            <div className="pop-up-form">

                                <div className="pop-up-top">
                                    <h2>Time Slot</h2>

                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>



                                </div>

                                <div className="container">
                                    <div className="row">
                                        {selectedSlot && (
                                            <div className="time-slots">
                                                {Array.isArray(fetchAvailableTimeSlots(selectedDate, selectedSlot)) ?
                                                    fetchAvailableTimeSlots(selectedDate, selectedSlot).map(time => (

                                                        <button
                                                            className={`m-0 pe-2 ps-1 box-hover animation `}
                                                            key={time}
                                                            onClick={() => handleTimeSelection(time)}

                                                        >
                                                            {time}
                                                        </button>

                                                    ))

                                                    :
                                                    <p>No available time slots</p>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}


        </>
    );
};

export default BrithdaySlot;
