import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import moment from "moment";

const UpcomingImmersions = () => {
  const navigate = useNavigate();
  const [festivals, setFestivals] = useState([]);

  const fetchFestivals = async () => {
    try {
      const res = await ApiService.fetchFestivals();
      setFestivals(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFestivals();
  }, []);

  return (
    <section className="upcoming-immersions">
      <div className="container">
        <div className="heading">
          <h2 className="head-1">UPCOMING IMMERSIONS</h2>
        </div>
        <div className="row pt-5">
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <a
              href="https://iskcontsp.org/"
              className="d-block text-decoration-none"
              target="_blank"
            >
              <div className="immersion-wrap">
                <figure>
                  <img
                    src="/images/homepage/immersions/upcoming-immersions-1.webp"
                    alt=""
                  />
                  <div className="immersion-text d-flex justify-content-center flex-column align-items-center">
                    <h4>HARINAM</h4>
                    <p className="pexternal">
                      SANKIRTAN
                      <FiExternalLink />
                    </p>
                  </div>
                </figure>
              </div>
            </a>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <Link to="/festivals" className="d-block text-decoration-none">
              <div className="immersion-wrap">
                <figure>
                  <img
                    src="/images/homepage/immersions/upcoming-immersions-2.webp"
                    alt=""
                  />
                  <div className="immersion-text d-flex justify-content-center flex-column align-items-center">
                    <h4>VAISHNAV</h4>
                    <p>FESTIVALS</p>
                  </div>
                </figure>
              </div>
            </Link>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <Link to="#" className="d-block text-decoration-none">
              <div className="immersion-wrap">
                <figure>
                  <img
                    src="/images/homepage/immersions/upcoming-immersions-3.webp"
                    alt=""
                  />
                  <div className="immersion-text d-flex justify-content-center flex-column align-items-center">
                    <h4>HOLY</h4>
                    <p>YATRAS</p>
                  </div>
                </figure>
              </div>
            </Link>
          </div>
        </div>
        <div className="row pt-sm-4 pt-1 ">
          <div className="col-lg-6 mb-lg-0 mb-4">
            <Accordion className="accordion-wrap">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordion">
                  HARINAM SANKIRTAN
                </Accordion.Header>
                <Accordion.Body className="accordion-panel">
                  <ul className="list-unstyled">
                    <li className="bullet">
                      <a
                        className="redirect-link"
                        href="https://iskcontsp.org/"
                        target="_blank"
                      >
                        On-road calendar for Travelling Sankirtan Party
                        <FiExternalLink />
                      </a>
                    </li>
                    <li className="bullet">
                      <a
                        className="redirect-link"
                        href="https://iskcontsp.org/get-involved/"
                        target="_blank"
                      >
                        Join the Bus <FiExternalLink />
                      </a>
                    </li>
                    <li className="bullet">
                      <a
                        className="redirect-link"
                        href="https://iskcontsp.org/spiritual-shop/"
                        target="_blank"
                      >
                        Purchase Spritual Books From us <FiExternalLink />
                      </a>
                    </li>
                    <li className="bullet">
                      <a
                        className="redirect-link"
                        href="https://chat.whatsapp.com/I5XRpmackVq2HSfAN9Zt5C"
                        target="_blank"
                      >
                        Join our WhatsApp Group <FiExternalLink />
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="accordion">
                  FESTIVALS
                </Accordion.Header>
                <Accordion.Body className="accordion-panel">
                  <ul className="list-unstyled">
                    {festivals?.slice(0, 3).map((fest) => (
                      <li key={fest._id} className={`bullet ${moment(fest.startDate) < moment() ? 'd-none' : ''}`}>
                        {fest?.title} {" "} {moment(fest?.startDate).format("DD.MM.YYYY")}
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex align-items-center dropdown-btn">
                    <Link
                      className="custom-btn-cls box-hover"
                      to="/donate-categories/#festivals"
                    >
                      SPONSOR A FESTIVAL
                    </Link>
                    <DropdownButton title="WATCH HERE">
                      <Dropdown.Item
                        href="https://www.mayapur.tv/"
                        target="_blank"
                      >
                        Mayapur TV
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="https://www.instagram.com/iskconbhiwandi/"
                        target="_blank"
                      >
                        Instagram
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="https://www.youtube.com/iskconbhiwandi"
                        target="_blank"
                      >
                        Youtube
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="accordion">
                  HOLY YATRAS
                </Accordion.Header>
                <Accordion.Body className="accordion-panel">
                  <ul className="list-unstyled">
                    <li className="bullet">
                      <a href="#"> Vrindavan Yatra</a>
                    </li>
                    <li className="bullet">
                      <a href="#"> SriDham Mayapur Yatra</a>
                    </li>
                    <li className="bullet">
                      <a href="#"> Tirupati Balaji Yatra</a>
                    </li>
                    <li className="bullet">
                      <a href="#"> Kartik Yatra</a>
                    </li>
                    <li className="bullet">
                      <a href="#"> Jagannath Puri Yatra</a>
                    </li>
                    {/* <Link className="custom-btn-cls box-hover" to="/yatras">
                      REGISTER NOW
                    </Link> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-lg-6">
            <Accordion className="accordion-wrap">
              <Accordion.Item eventKey="4">
                <Accordion.Header className="accordion">
                  WEEKDAY'S SCHEDULE
                </Accordion.Header>
                <Accordion.Body className="accordion-panel">
                  <ul className="list-unstyled">
                    <li>Mangal Aarti 4:30 am</li>
                    <li>Sringar Darshan 7:30 am</li>
                    <li>Srimad Bhagavatam Discourse 8:00 am</li>
                    <li>Raj Bhoga Aarti 12:30 pm</li>
                    <li>Uthapana Aarti 4:30 pm</li>
                    <li>Sandhya Aarti 7:00 pm</li>
                    <li>Bhagavad Gita Discourse 7:30pm</li>
                    <li className="title">Darshan Timings:</li>
                    <li>4:30 am to 1:00 pm</li>
                    <li>4:30 pm to 08.30 pm</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="accordion">
                  SUNDAY SCHEDULE
                </Accordion.Header>
                <Accordion.Body className="accordion-panel">
                  <ul className="list-unstyled">
                    <li className="title">Congregation Class:</li>
                    <li>11:00 am 12:30 pm </li>
                    <li className="title">Youth Class:</li>
                    <li>11:00 am-12:30 pm</li>
                    <li className="title"> Raj Bhoga Arati and Kirtan:</li>
                    <li>12:30 pm-1:00 pm</li>
                    <li className="title">Lunch Prasadam for all:</li>
                    <li>01:00 pm to 2:30 pm</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="accordion">
                  ANNADAAN OUTREACH
                </Accordion.Header>
                <Accordion.Body className="accordion-panel">
                  <ul className="list-unstyled">
                    <li>
                      1000 meals everyday across Kamatghar, Padma Nagar, Anjur
                      Phata, Gayatri Nagar, Dhamankar Naka, Temghar Pada, Tembhavali, Firangipada and Paigaon
                    </li>
                  </ul>
                  <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-3">
                    <Link className="custom-btn-cls box-hover" to="/volunteer">
                      Click to volunteer
                    </Link>

                    <Link
                      className="custom-btn-cls box-hover mt-3 mt-sm-0"
                      to="/annadaan"
                    >
                      Click to feed the hungry
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 mx-auto text-center">
            <Link
              to="/festivals"
              className="custom-btn-cls box-hover text-uppercase"
            >
              View all festivals
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingImmersions;
