import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiService from '../api/ApiService'
import PageHeader from '../components/common/PageHeader'
import MetaData from '../helpers/MetaData'
import notification from '../helpers/notification'

const LiveVideo = () => {

    const [liveVideo, setLiveVideo] = useState(null);
    const navigate = useNavigate()

    const fetchLiveVideos = async () => {
        try {
            const res = await ApiService.fetchLiveVideos();
            if(!res.status) {
                navigate('/', {
                    replace: true
                })
            }
            setLiveVideo(res)
        }catch(err) {
            console.log(err)
            notification('error', 'Something went wrong!')
        } 
    } 

    useEffect(() =>{
        fetchLiveVideos()
    },[])

  return (
    <>
        <MetaData title="Watch Live - ISKCON of Bhiwandi" />
        <PageHeader title="Watch live" imgSrc="/images/page-header/07.webp" />
        <section className='live-video'>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-8">
                        <div className="heading  d-flex align-items-center  flex-column">
                            <h2 className="head-1">Watch Live Video</h2>
                            <p>
                                Watch this space for Live Streaming of ISKCON Bhiwandi events like Rath Yatra, Annadaan drives, special discourses and Festival special aartis.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-10 mx-auto">
                        <div className="live-video-wrap">
                            <iframe src={liveVideo?.link}  allowFullScreen="allowfullscreen"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default LiveVideo