import moment from 'moment';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import platform from 'platform'
import notification from '../../helpers/notification';
import fileDownload from 'js-file-download'
import ApiService from '../../api/ApiService';


function FestivalCard({festival}) {
    // console.log(platform.os.family)
    const navigate = useNavigate()
    const handleCalenderDownload=async(file)=>{
      try{
        const res=await ApiService.downloadCalendar(file)  
        fileDownload(res,'calendar-file.ics')
      }catch(error){
          console.log(error)
          notification('error','Unable to download calendar file!')
      }
    }
  return (
    <div className="content-card">

        <figure>
            <img src={`${process.env.REACT_APP_S3_URL}/uploads/festival/${festival?.image}`} alt=""/>
            <div className="btn-flex">
                {
                    (festival?.iosFile && festival?.androidCalenderLink && festival?.androidCalenderLink?.length > 5) &&
                    <>
                        {
                            platform.os.family === 'iOS' || platform.os.family === 'OS X'? <button className='box-hover' onClick={()=>handleCalenderDownload(festival.iosFile)}>Add to Calendar</button> :
                            <a className='box-hover' href={festival.androidCalenderLink} target="_blank">Add to Calendar</a>
                        }
                    </>
                }
                <button onClick={() => navigate(`/donation-form/${festival.slug}`)} className="box-hover">Sponsor the festival</button>
            </div>
        </figure>
        <div className="content-info">

            <a href="#">

                <h2>{festival?.title}</h2>
            </a>
            <span></span>
            <h5>
                {
                moment(festival.startDate).format('DD/MM/YYYY') === moment(festival.endDate).format('DD/MM/YYYY')?
                moment(festival.startDate).format('dddd, D MMMM YYYY'):
                moment(festival.startDate).format('dddd, D MMMM') + " - " +  moment(festival.endDate).format('dddd, D MMMM YYYY')
                }
            </h5>
            <p>
                {festival?.description}
            </p>
            </div>
        </div>
  )
}

export default FestivalCard