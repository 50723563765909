import { FaFacebookF } from 'react-icons/fa'
import { BsYoutube } from 'react-icons/bs'
import { FiInstagram } from 'react-icons/fi'
import { SiGmail } from 'react-icons/si'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from "../../api/ApiService";
import WhatsAppLink from '../../helpers/WhatsAppLink';
import notification from "../../helpers/notification";
import { useEffect, useState } from 'react';
const Footer = () => {

    const [schedule, setSchedule] = useState([]);


    const fetchSheduleData = async () => {
        try {
            const res = await ApiService.fetchSheduleData();
            setSchedule(res.sheduleds);

            console.log(res);

        } catch (err) {
            console.log(err);
            notification("error", err.response.data.message);
        }
    };

    useEffect(() => {
        fetchSheduleData();
    }, []);


  return (
      <>
    <footer>
        <div className="container-fluid px-0">
            <div className="row m-0">
                <div className="col-lg-4 px-0 order-lg-1 ">
                    <div className="footer-content always-dark">
                        <h5>AARTIS AND DISCOURSES</h5>
                        <div className="footer-details">
                            <p>
                            Mangal Aarti - 04:30 am <br/>
                            Sringar Darshan - 07:30 am <br/>
                            Srimad Bhagavatam Discourse - 08:00 am <br/>
                            Raj Bhoga Aarti - 12:30 pm <br/>
                            Uthapana Aarti - 04:30 pm <br/>
                            Sandhya Aarti - 07:00 pm <br/>
                            Bhagavad Gita Discourse - 07:30 pm
                            </p>
                        </div>
                        <div className="footer-details">
                            <h6>Darshan Timings</h6>
                            <p>
                                04:30 am to 01:00 pm <br/>
                                04:30 pm to 08:30 pm
                                <span className="inner-para d-block">
                                    Temple timings may be changed on special occasions.
                                </span>
                            </p>
                        </div>
                    
                    </div>
                </div>
                <div className="col-lg-4 order-lg-2 order-3">
                    <div className="footer-content first-dark">

                        <a href="/about-us/#founder">

                        <figure className="mb-4 footer-img">
                            <img className="w-100" src="/images/footer/footer-img.png" alt="" />
                        </figure>
                        </a>
                        <div className="footer-details">
                            <h6>ISKCON Bhiwandi</h6>
                        <a target="_blank" href="https://www.google.com/maps/place/ISKCON+Bhiwandi/@19.2792965,73.0625967,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7bd98e9789d5f:0x3a60e0edeab7fa5c!8m2!3d19.2792606!4d73.064783"> <p>Sri Sri Radha Madhava mandir, ISKCON Bhiwandi,<br/> Hare Krishna hill, Mansarovar, Bhiwandi  <br/>
                            Maharashtra 421 305</p>
                        </a>
                        </div>
                        <h5 className="mb-4">FOLLOW US</h5>
                        <div className="social-links">
                            <a target="_blank" href="https://facebook.com/iskconbhiwandi"><FaFacebookF /></a>
                            <a target="_blank" href="https://www.youtube.com/ISKCONBHIWANDI "><BsYoutube /></a>
                            <a target="_blank" href="https://www.instagram.com/iskconbhiwandi"><FiInstagram /></a>
                        </div>
                        <p className="inner-para text-center mt-4">
                            Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada Founder Acharya of the International Society for Krishna Consciousness.
                        </p>
                        <p className="mt-2 mb-0">
                            &copy; {new Date().getFullYear()} ISKCON of Bhiwandi <span className='d-inline-block mx-2'>|</span> All Rights Reserved.
                        </p>
                        <Link className='sitemap-link' to="/sitemap">SITEMAP</Link>
                    </div>
                </div>
                <div className="col-lg-4 px-0 order-lg-3 order-2">
                    <div className="footer-content second-dark">
                        <h5>SUNDAY SCHEDULE</h5>
                        {/* <div className="footer-details">
                            <p>
                            Congregation class:<br/>
                            11:00 am - 12.30 pm<br/>

                            Sunday youth class:<br/>
                            11:00 am - 12.30 pm<br/>

                            Raj Bhoga Aarti and Kirtan:<br/>
                            12.30 pm - 01.00 pm<br/>

                            Lunch Prasadam for all:<br/>
                            01:00 pm - 2.30 pm<br/>
                            </p>
                            
                        </div> */}
                        <div className="footer-details">
                                    {schedule.map((item) => (
                                        <p key={item._id}>
                                            {item.className_a}:<br />
                                            {item.from_a} - {item.to_a}
                                        </p>
                                    ))}
                                </div>
                        
                            <figure className="international-logo">
                                <img src="/images/footer/international-logo.png" alt="" />
                            </figure>
                    </div>
                </div>
            </div>
        </div>    
    </footer>
    <ToastContainer />
    <WhatsAppLink/>
    </>
  )
}

export default Footer