import { useEffect, useRef, useState } from "react";
import { ImMusic } from "react-icons/im";
import { MdChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";
import ApiService from "../../api/ApiService";
import notification from "../../helpers/notification";
import { Modal, ModalTitle } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import moment from "moment";

const Cta = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedSerivce] = useState(null);
  const recaptchaRef = useRef();

  const fetchServices = async () => {
    try {
      const res = await ApiService.fetchServices();
      const filterServices = res.filter((service) => service.homepage);
      // console.log(filterServices)
      setServices(filterServices);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFormSubmit = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      data.token = token;

      await ApiService.createEnquiry({
        service: selectedService?._id,
        type: "service",
        ...data,
      });
      notification("success", "Hare Krishna! Your form has been accepted.");
      reset();
      setShowModal(false);
      if (selectedService?.redirect) {
        navigate("/bhagavad-gita");
      }
    } catch (error) {
      console.log(error);
      notification("error", error.response.data.message);
    }
  };
  const handleServiceClicked = (service) => {
    setSelectedSerivce(service);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
    reset();
  };

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchServices();
  }, []);
  return (
    <section className="cta position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="fta-content">
              <p>ISKCON OF BHIWANDI</p>
              <h1>
                BLISS <br /> OF BHAKTI
              </h1>
            </div>
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="fta-links">
              {services.map((service, i) => (
                <button
                  key={i}
                  onClick={() => {
                    handleServiceClicked(service);
                  }}
                  className="btn p-0"
                >
                  <div className="link-wrap box-hover">
                    {service?.homepageSvg && (
                      <div className="icon">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service?.homepageSvg,
                          }}
                        ></div>
                      </div>
                    )}
                    <p className="text-uppercase">
                      {service.homepageTitle?.split("|")[0]}
                      <span className="d-block">
                        {service.homepageTitle?.split("|")[1]}
                        <MdChevronRight />
                      </span>
                    </p>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={showModal} onHide={handleHideModal}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={handleHideModal}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img
                  src={`${process.env.REACT_APP_S3_URL}/uploads/service/${selectedService?.image}`}
                  alt=""
                />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">
                <div className="pop-up-top">
                  <div className="heading">
                    <h2 className="head-2">
                      {selectedService?.homepageTitle.split("|")[0]}
                    </h2>
                    <h2 className="head-1">
                      {selectedService?.homepageTitle.split("|")[1]}
                    </h2>
                  </div>

                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <p>{selectedService?.description}</p>
                <form
                  action=""
                  className="mt-3 mt-sm-0"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <div className="row">
                    {selectedService?.inputAddOns?.includes(
                      "Name of Child"
                    ) && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="nameOfChild">Name of Child</label>
                          <input
                            type="text"
                            id="nameOfChild"
                            {...register("nameOfChild", { required: true })}
                            onKeyPress={(event) => {
                              if (/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.nameOfChild?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns?.includes("Age of Child") && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="ageOfChild">Age of Child</label>
                          <input
                            type="text"
                            id="ageOfChild"
                            {...register("ageOfChild", { required: true })}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.ageOfChild?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns?.includes(
                      "Name of Parent"
                    ) && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="nameOfParent">Name Of Parent</label>
                          <input
                            type="text"
                            id="nameOfParent"
                            {...register("nameOfParent", { required: true })}
                            onKeyPress={(event) => {
                              if (/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.nameOfParent?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes("Name") && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            id="name"
                            {...register("name", { required: true })}
                            onKeyPress={(event) => {
                              if (/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.name?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes("Email") && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            {...register("email", {
                              required: true,
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email",
                              },
                            })}
                          />
                          {errors.email?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.email && (
                            <small className="text-danger error">
                              {errors.email.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes("Phone") && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="text"
                            id="phone"
                            maxLength={10}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("phone", {
                              required: true,
                              pattern: {
                                value: /^[6-9]\d*$/,
                                message: "Please provide valid phone number",
                              },
                              minLength: {
                                value: 10,
                                message: "Phone must be minimum 10 digit",
                              },
                              maxLength: {
                                value: 10,
                                message: "Phone must be maximum 10 digit",
                              },
                            })}
                          />
                          {errors.phone?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.phone && (
                            <small className="error">
                              {errors.phone.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes("Location") && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="location">Location</label>
                          {/* <select id="location" {...register("location",{required:true})}>
                              <option value="">Select location</option>
                              <option value="Bhiwandi">Bhiwandi</option>
                              <option value="Thane">Thane</option>
                              <option  value="Mumbai">Mumbai</option>
                             </select> */}
                          <input
                            type="text"
                            id="location"
                            {...register("location", { required: true })}
                          />
                          {errors.location?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes("Address") && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            id="address"
                            {...register("address", { required: true })}
                          />
                          {errors.address?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes(
                      "No. of singers"
                    ) && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="noOfSingers">No of singers</label>
                          <input
                            type="text"
                            id="noOfSingers"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("noOfSingers", {
                              required: true,
                              pattern: {
                                value: /^\d+$/,
                                message: "No of singers must be number",
                              },
                            })}
                          />
                          {errors.noOfSingers?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.noOfSingers && (
                            <small className="error">
                              {errors.noOfSingers.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes(
                      "Size of gathering"
                    ) && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="sizeOfGathering">
                            Size of Gathering
                          </label>
                          <input
                            type="text"
                            id="sizeOfGathering"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("sizeOfGathering", {
                              required: true,
                              pattern: {
                                value: /^\d+$/,
                                message: "Size of gathering must be number",
                              },
                            })}
                          />
                          {errors.sizeOfGathering?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.sizeOfGathering && (
                            <small className="error">
                              {errors.sizeOfGathering.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes(
                      "Preferred Time"
                    ) && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="referredTime">Preferred Time</label>
                          <input
                            type="time"
                            id="referredTime"
                            {...register("referredTime", { required: true })}
                          />
                          {errors.referredTime?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.referredTime && (
                            <small className="error">
                              {errors.referredTime.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes(
                      "Preferred Date"
                    ) && (
                      <div className="col-sm-6">
                        <div className="label-input-wrap">
                          <label htmlFor="referredDate">Preferred Date</label>
                          <input
                            type="date"
                            min={moment(Date.now()).format("YYYY-MM-DD")}
                            id="referredDate"
                            {...register("referredDate", { required: true })}
                          />
                          {errors.referredDate?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                          {errors.referredDate && (
                            <small className="error">
                              {errors.referredDate.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns.includes("Special Note") && (
                      <div className="col-12">
                        <div className="label-input-wrap">
                          <label htmlFor="specialNote">Special Note</label>
                          <textarea
                            type="text"
                            id="specialNote"
                            {...register("specialNote")}
                          />
                          {/* {errors.specialNote?.type === 'required' && <small className="error">This is required</small> } */}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns?.includes("Course Note") && (
                      <div className="col-12">
                        <div className="label-input-wrap">
                          {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                          <label htmlFor="courseNote">
                            {" "}
                            What are you seeking to gain from this course?
                          </label>
                          <textarea
                            type="text"
                            id="courseNote"
                            {...register("courseNote", { required: true })}
                          />
                          {errors.courseNote?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    {selectedService?.inputAddOns?.includes("Session Note") && (
                      <div className="col-12">
                        <div className="label-input-wrap">
                          {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                          <label htmlFor="sessionNote">
                            What are you seeking to gain from this session?
                          </label>
                          <textarea
                            type="text"
                            id="sessionNote"
                            {...register("sessionNote", { required: true })}
                          />
                          {errors.sessionNote?.type === "required" && (
                            <small className="error">This is required</small>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-12">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </div>
                  </div>
                  <div className="pop-form-btn">
                    <button className="box-hover">
                      Submit
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default Cta;
