import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";
//http://localhost:3000/dashboard?link=12345
const ProtectedRoute = ({ children }) => {
    const search = useLocation().search;
    const link_id = new URLSearchParams(search).get("link");
    
    const {token} = React.useContext(AppContext)
    return token ? children : <Navigate to={link_id ? '/login?link='+link_id : '/login'} />

};

export default ProtectedRoute;
