import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LoaderScreen = () => {
  return (
    <>
        <section style={{height: '100vh'}}>
            <div className="container">
                <Skeleton height={500} baseColor="#fff" highlightColor="#fff5dc21" />
            </div>
        </section>
    </>
  )
}

export default LoaderScreen