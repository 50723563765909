
const AboutYatras = () => {
    return (
        <section className="yatras">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="heading">
                <h2 className="head-1">TRAVEL TO THE LORD </h2>
                <p className="b-line">
                  Come together in a divine congregation of seekers and travel
                  to Holy Dhams singing, meditating and listening to the leelas
                  of the Lord. ISKCON of Bhiwandi organises well-planned yatras
                  for the spiritually-inclined that help deepen your connection
                  with the divine.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default AboutYatras