import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from "react-router-dom";
import ApiService from "../api/ApiService";
import { Modal, ModalTitle } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import moment from "moment/moment";
import PageHeader from "../components/common/PageHeader";
import "../css/brithdaySlot.css";

// import '../src/App.css';

const BirthdayLoginForm = () => {



  const navigate = useNavigate();


  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    //console.log("elements",element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  };



  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();




  const birthdayLogin = async (birthdayLoginData) => {
    //console.log(birthdayLoginData)
    try {


      let res = await ApiService.birthdayLogin(birthdayLoginData);


      if (res.message === 'Login successfully') {
        notification("success", "Hare Krishna! Welcome " + res.name);
        //console.log(res, res.birthdaytoken)
        localStorage.setItem(`birthday_token`, res.birthdaytoken);
        localStorage.setItem(`birthday_phone`, res.phone);
        localStorage.setItem(`birthday_name`, res.name);
        localStorage.setItem(`birthday_bookby`, res.bookby);

        navigate('/brithday_slot')
      } else {
        notification("error", "Please Check your Phone or Password !");
        reset();
      }
      console.log("resrs", res.message);

    } catch (error) {
      console.error("Error Login data:", error);

    }
  };





  // const handleViewButtonClick = async () => {

  //     navigate('/view_slot');
  // };





  useEffect(() => {
    scrollToElement();
  }, [])


  // useEffect(() => {
  //     fetchBookedSDateWiselots(selectedDate);
  // }, [selectedDate]);

  return (
    <>
      <PageHeader title="Login Page" imgSrc="/images/page-header/13.webp" />
      <section className="connect-heading-sec" style={{ padding: '7px 0' }}>
        <div className="container" >
          <div className="row" id="scrollToAllPages">
            <div className="col-lg-10 mx-auto">
              <div className="heading">
                <h4 className="head-1" style={{ fontSize: '21px' }}>LOGIN HERE TO MANAGE PUJA</h4>
                {/* <p className="b-line">
                  We are so happy that you took the time to visit us and we
                  welcome you to the ISKCON Attapur devotional family. Please
                  write in to us for any questions, to offer your services to
                  Sri Sri Radha Madhava, for information on any of our
                  services and courses, or any suggestions. We are here to serve
                  you, Hare Krishna!
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-wrap" style={{ padding: '14px 0' }}>
        <div className="container">
          <div className="row">

            <div className=" col-lg-6 order-0 order-lg-1 " >
              <div className="contact-form bg-white p-5" style={{ border: '3px solid black', borderRadius: '10px' }} >
                {/* <div className="form-title mb-5">
                  <h4>
                    Login <span>form</span>
                  </h4>
                </div> */}
                <form
                  className="order-0 order-lg-1"
                  onSubmit={handleSubmit(birthdayLogin)}
                >
                  <div className="row align-items-center">
                    <div className="col-sm-12">
                      <div className="input-wrap">
                        <label htmlFor="firstname" className="imp">
                          Phone
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^\d+$/,
                              message: "Mobile number must be number.",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone number must be minimum 10 digit",
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="input-wrap">
                        <label htmlFor="email" className="imp">
                          Password
                        </label>
                        <input
                          type="password"
                          id="password"
                          {...register("password", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.password?.type === "required" && (
                          <span>  Password is required </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <button className="box-hover custom-btn" type="submit">
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0 order-1 order-lg-0">
              <div className="contact-address">
                <div className="contact-logo mt-2">
                  <figure className="mb-0">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div style={styles.container}>
        <h1 style={{ textAlign: "center" }}>Birthday Login </h1>
        <form
          action=""
          className="mt-3 mt-sm-0"
          onSubmit={handleSubmit(birthdayLogin)}
        >
          <div className='textarea'>
            <label htmlFor="phone" className="imp">Mobile Number</label>
            <div className="label-input-wrap">

              <input
                type="tel"
                id="phone"
                maxLength={10}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...register("phone", {
                  required: true,
                  pattern: {
                    value: /^\d+$/,
                    message: "Mobile number must be number.",
                  },
                  minLength: {
                    value: 10,
                    message: "Phone number must be minimum 10 digit",
                  },
                })}
              />
            </div>
            <label htmlFor="password" className="imp">Password</label>
            <div className="label-input-wrap textarea">

              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                onKeyPress={(event) => {
                  if (/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {errors.password?.type === "required" && (
                <span>  Password is required </span>
              )}
            </div>
          </div>

          <button className="box-hover custom-btn" style={{ marginTop: '5px' }} type="submit">Login</button>
        </form>
      </div> */}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    marginTop: "200px",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: "100%",
    boxSizing: "border-box",
  },

};

export default BirthdayLoginForm;