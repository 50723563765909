import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useEffect, useLayoutEffect, useRef } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const PageHeader = ({title, imgSrc, hasBtn, description=''}) => {

  const pageHeaderRef = useRef()

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.create({
      trigger: pageHeaderRef.current,
      start: 'top top',
      pin: true,
      pinSpacing: false
    })

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill())
    };

  })

  return (
    <section className="page-header" ref={pageHeaderRef} >
      <div className="container">
        <figure>
            {
              imgSrc ?
              <img src={imgSrc} alt={title} />
              :
              <Skeleton width={'100%'} height={'100%'} />
            }
        </figure>
          <div className="page-content">
            {
              title ?
              <h2 className="text-uppercase">{title}</h2>
              :
              <SkeletonTheme baseColor="#fef4db70" highlightColor="#fef4db38">
                <Skeleton width={300} height={100} />
              </SkeletonTheme>
            }
            {
              hasBtn &&
              <button className="box-hover">JOIN THE DEVOTIONAL FAMILY</button>
            }
            {
              description &&
              <p className="_desc">
                {description}
              </p>
            }
          </div>
      </div>
    </section>
  )
}

export default PageHeader