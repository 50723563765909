import { useEffect,useState,useRef, useCallback } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Skeleton from 'react-loading-skeleton';
import ApiService from '../../api/ApiService';
import { useLocation } from 'react-router-dom'

const DonationCampaigns = ({setTop}) => {

    const location = useLocation()

    const [popular,setPopular] = useState([])
    const [festivals,setFestivals] = useState([])
    const [donationTypes,setDonationTypes]=useState([])
    const [categories,setcategories]=useState([])
    const fetchDonationTypes=async()=>{
      try{
        const res= await ApiService.fetchDonationServices()

        setPopular(res.popularServices)
        setFestivals(res.festivals)
        setcategories(res.categories)
        setDonationTypes(res.services)
      }catch(error){
        console.log(error)
      }
    }
    
    useEffect(() => {
        document.title = "ISKCON Bhiwandi | Donate"
        fetchDonationTypes()
    }, [])

    const festRef = useCallback(node => {
        if(node !== null && location.hash) {
            setTop(node.offsetTop + 300)
        }
    })


    const DonationAccordian=({category})=>{
    
        const data=donationTypes.filter(type=>type.category === category._id)
    
        return (
            <Accordion.Item eventKey={category._id}>
                <Accordion.Header>
                    <span className="h-icon" dangerouslySetInnerHTML={{__html:category.icon}}>
                    </span>
                    <span>
                        {category.name}
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="row w-100 m-0">
                    {
                        data.map(donationType=>(
                            <div key={donationType._id} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3">
                                <a href={`/donation-form/${donationType.slug}`}>
                                    <div>
                                        <span>{donationType.title.split("|")[0]}</span>
                                        {donationType.title.split("|")[1] && <span className='d-block'>{donationType.title.split("|")[1]}</span> }
                                    </div>
                                    <span className="icon">
                                        <svg className="donate-arrow" stroke="currentColor" fill="currentColor"
                                            strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z">
                                            </path>
                                        </svg>
                                    </span>
                                    <div className="camp-tooltip d-none">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                                        obcaecati!
                                    </div>
                                </a>
                            </div>
                        ))
                    }
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        )
    }
    return (
        <section className="donate-new">
            <div className="container">

                <div className="row mb-5">
                    <div className="col-md-10 mx-auto">
                        <div className="heading">
                            <h2 className="head-1">TURN YOUR MONEY INTO PRASAD</h2>
                            <p className='b-line'>If you would like to make a donation towards a particular area of activity, please select an option from below.
                                ISKCON relies entirely on voluntary donations and so every donation counts.
                                Please note that donation is processed on a secure site.
                            </p>
                        </div>
                    </div>
                </div>

                {
                    donationTypes?.length <= 0 ?<>
                         <div className='mb-4'>
                            <div className='d-flex align-items-center'>
                            <Skeleton circle={true} height={30} width={30}/>
                            <Skeleton  width={200} className="ms-2"/>
                            </div> 
                            <Skeleton height={35} className="mt-1"/>
                         </div>
                         <div className='mb-4'>
                            <div className='d-flex align-items-center'>
                            <Skeleton circle={true} height={30} width={30}/>
                            <Skeleton  width={200} className="ms-2"/>
                            </div> 
                            <Skeleton height={35} className="mt-1"/>
                         </div>
                         <div className='mb-4'>
                            <div className='d-flex align-items-center'>
                            <Skeleton circle={true} height={30} width={30}/>
                            <Skeleton  width={200} className="ms-2"/>
                            </div> 
                            <Skeleton height={35} className="mt-1"/>
                         </div>
                         <div className='mb-4'>
                            <div className='d-flex align-items-center'>
                            <Skeleton circle={true} height={30} width={30}/>
                            <Skeleton  width={200} className="ms-2"/>
                            </div> 
                            <Skeleton height={35} className="mt-1"/>
                         </div>
                      
                    </>:
                      <div className="accordion_wrap">
                      <Accordion defaultActiveKey={location.hash ? '1' : '0'}>
                          <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                  <span className="h-icon">
                                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                              d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                                          </path>
                                      </svg>
                                  </span>
                                  <span>
                                      POPULAR DONATIONS
                                  </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                  <div className="row w-100 m-0">
                                      {
                                          popular?.map(popularItem=>(
                                           <div key={popularItem._id} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3">
                                              <a href={`/donation-form/${popularItem.slug}`}>
                                              <div>
                                                <span>{popularItem.title.split("|")[0]}</span>
                                                {popularItem.title.split("|")[1] && <span className='d-block'>{popularItem.title.split("|")[1]}</span> }
                                              </div>
                                                  <span className="icon">
                                                      <svg className="donate-arrow" stroke="currentColor" fill="currentColor"
                                                          strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                          <path
                                                              d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z">
                                                          </path>
                                                      </svg>
                                                  </span>
                                                  <div className="camp-tooltip d-none">
                                                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                                                      obcaecati!
                                                  </div>
                                              </a>
                                          </div>
                                          ))
                                      }   
                                  </div>
                              </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                              <Accordion.Header >
                                  <span className="h-icon">
                                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024"
                                          height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                              d="M608.2 423.3L512 326.1l-96.2 97.2zm-25.9 202.3l147.9-166.3h-63.4zm90-202.3h62.5l-92.1-115.1zM880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-81.3 332.2L515.8 762.3c-1 1.1-2.4 1.7-3.8 1.7s-2.8-.6-3.8-1.7L225.3 444.2a5.14 5.14 0 0 1-.2-6.6L365.6 262c1-1.2 2.4-1.9 4-1.9h284.6c1.6 0 3 .7 4 1.9l140.5 175.6a4.9 4.9 0 0 1 0 6.6zm-401.1 15.1L512 684.5l114.4-225.2zm-16.3-151.1l-92.1 115.1h62.5zm-87.5 151.1l147.9 166.3-84.5-166.3zm126.5-158.2l-23.1 89.8 88.8-89.8zm183.4 0H538l88.8 89.8z">
                                          </path>
                                      </svg>
                                  </span>
                                  <span>
                                      EVENTS & FESTIVALS
                                  </span>
                              </Accordion.Header>
                              <Accordion.Body ref={festRef}>
                                  <div className="row w-100 m-0">
                                     {
                                         festivals.map(festival=>(
                                          <div key={festival._id} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3">
                                              <a href={`/donation-form/${festival.slug}`}>
                                                <div>
                                                    <span>{festival.title.split("|")[0]}</span>
                                                    {festival.title.split("|")[1] && <span className='d-block'>{festival.title.split("|")[1]}</span> }
                                                </div>
                                                  <span className="icon">
                                                      <svg className="donate-arrow" stroke="currentColor" fill="currentColor"
                                                          strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                          <path
                                                              d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z">
                                                          </path>
                                                      </svg>
                                                  </span>
                                                  <div className="camp-tooltip d-none">
                                                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                                                      obcaecati!
                                                  </div>
                                              </a>
                                          </div>
                                         ))
                                     }
                                  </div>
                              </Accordion.Body>
                          </Accordion.Item>
                          {
                              categories.map((category, i)=>(
                                 <DonationAccordian key={i} category={category}/>
                              ))
                          }
                           <Accordion.Item eventKey="membership">
                              <Accordion.Header>
                                  <span className="h-icon">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path></svg>
                                  </span>
                                  <span>
                                      Membership
                                  </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                  <div className="row w-100 m-0">
                                     {
                                          <div  className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3">
                                              <a href="/life-membership/#membership">
                                                <div>
                                                    <span>Life Membership</span>
                                                </div>
                                                  <span className="icon">
                                                      <svg className="donate-arrow" stroke="currentColor" fill="currentColor"
                                                          strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                          <path
                                                              d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z">
                                                          </path>
                                                      </svg>
                                                  </span>
                                                  <div className="camp-tooltip d-none">
                                                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                                                      obcaecati!
                                                  </div>
                                              </a>
                                          </div>
                                     }
                                  </div>
                              </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>
  
                      </div>
                }

              
            </div>
        </section>
    )
}


export default DonationCampaigns