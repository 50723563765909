import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from "react-router-dom";
import ApiService from "../api/ApiService";
import { Modal, ModalTitle } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import CalendarSlot from "./CalendarForSlotBook.jsx"
import "../css/brithdaySlot.css";
import { HiOutlineArrowLeft, HiOutlineLogout, HiOutlineArrowRight, HiPencilAlt } from "react-icons/hi";

// import '../src/App.css';

const Calendar = () => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOpenn, setIsDialogOpenn] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedTime, setSelectedTime] = useState([]);
    const [calendar, setCalendar] = useState([]);
    const [selectedBrithdays, setSelectedSerivce] = useState(null);
    const [bookedSlots, setBookedSlots] = useState(null);
    const [allBookedSlots, setAllBookedSlots] = useState([]);
    const [showActionButton, setShowActionButton] = useState(true);
    const [birthdayBookby, setBirthdayBookby] = useState('');
    const [showSlots, setShowSlots] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [disabledButtons, setDisabledButtons] = useState([]);
    //console.log("selectedDate", selectedDate);
    // const handleDateClick = (dayNumber) => {



    //     const clickedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), dayNumber);

    //     const formattedDate = `${clickedDate.getDate()}-${clickedDate.getMonth() + 1}-${clickedDate.getFullYear()}`;
    //     setSelectedDate(formattedDate);
    //     setSelectedSlot(null);
    //     setSelectedTime(null);
    //     setShowSlots(true);
    // };
    const generateCalendar = (month) => {
        const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
        const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        const calendar = [];

        for (let d = new Date(startOfMonth); d <= endOfMonth; d.setDate(d.getDate() + 1)) {
            calendar.push(d.getDate()); // Push day numbers instead of dates
        }

        return calendar;
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [cMonth, setCMonth] = useState(new Date());
    const goToNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));

        setCMonth(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
        fetchBookedSDateWiselots(selectedDate);

    };

    const goToPreviousMonth = () => {

        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
        setCMonth(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
        fetchBookedSDateWiselots(selectedDate);

    };
    // console.log("cMonth", cMonth);
    const fetchAvailableTimeSlots = (date, slot) => {
        //  console.log("Date and Slot:", date, slot);
        const sdate = new Date(moment(date, 'D-M-YYYY').format('YYYY-MM-DD'));
        //   console.log(sdate);
        const dayOfWeek = sdate.getDay();
        //  console.log("dayOfWeek",dayOfWeek);

        if (slot === 'morning' && dayOfWeek === 0) {
            return [];
        } else if (slot === 'evening' && (dayOfWeek === 0 || dayOfWeek === 6)) {
            return [];
        } else if (slot === 'morning') {
            return [
                '10:30AM-10:45AM',
                '10:45AM-11:00AM',
                '11:00AM-11:15AM',
                '11:15AM-11:30AM',
            ];
        } else if (slot === 'evening') {
            return [
                '5:00PM-5:15PM',
                '5:15PM-5:30PM',
                '5:30PM-5:45PM',
            ];
        } else {
            return [];
        }
    };


    const fetchBookedSlots = async () => {
        try {
            const formattedDate = moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.sssZ');
            // console.log(formattedDate,selectedTime);

            const response = await ApiService.fetchBookedSlots(formattedDate, selectedTime);
            //   console.log("booked slot", response)
            const numberOfBookedSlots = response.bookedSlot.length;
            if (numberOfBookedSlots >= 3) {

                setSelectedTime(null);
            }
            setBookedSlots(response.bookedSlot.length);
        } catch (error) {
            console.error("Error fetching booked slots:", error);
        }
    };

    const fetchBookedSDateWiselots = async (selectedDate) => {
        try {
            const formattedDate = moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.sssZ');
            // console.log(formattedDate);
            const response = await ApiService.fetchAllBookedDateSlots(formattedDate);
            //console.log("All Booked", response)

            const numberOfBookedDateWiseSlots = response.bookedSlot;

            // console.log("numberOfBookedDateWiseSlots", numberOfBookedDateWiseSlots)

            setAllBookedSlots(numberOfBookedDateWiseSlots)

            // const numberOfBookedDateWiseSlots = response.bookedSlot.length;
            // if (numberOfBookedDateWiseSlots >= 12) {

            //    setAllBookedSlots(true)
            // }

        } catch (error) {
            console.error("Error fetching booked slots:", error);
        }
    };


    const handleDateSelection = (dayNumber) => {
        const clickedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), dayNumber);
        // console.log("handeldate");
        const formattedDate = `${clickedDate.getDate()}-${clickedDate.getMonth() + 1}-${clickedDate.getFullYear()}`;
        //console.log("formattedDate", formattedDate)
        setSelectedDate(formattedDate);
        setSelectedTime(null);
        setShowSlots(true);
    };

    const handleSlotSelection = (slot) => {
        // console.log(slot)

        setSelectedSlot(slot);
        setShowSlots(false);
        setIsDialogOpenn(true);


    };
    const handleTimeSelection = (time) => {
        setSelectedTime(time);
        setIsDialogOpen(true);
        setIsDialogOpenn(false);
    };

    const isTimeSlotPassed = (timeSlot) => {
        const selectedDateTime = moment(`${selectedDate} ${timeSlot.split('-')[0]}`, 'DD-MM-YYYY h:mm A');
        const currentDateTime = moment();

        return selectedDateTime.isBefore(currentDateTime);
    };
    const handleBrithdaySave = async (data) => {
       // console.log("selectedDate,", selectedDate);
        // console.log("data,", data);

        try {
            data.selectedDate = moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
            data.selectedTime = selectedTime;

            const res = await ApiService.brithdaySlotBook(data).then(resp => console.log(resp)).catch(error => console.log("", error));
            if (res) {
                setSelectedTime([]);
                fetchBookedSDateWiselots();
            }

            notification("success", "Hare Krishna! Your Slot has been Booked.");
            reset();
        } catch (err) {
            console.log(err);
            notification("error", err.response.data.message);
        }
        setIsDialogOpen(false);
        setIsDialogOpenn(false);
    };

    const handleViewButtonClick = async () => {

        navigate('/view_slot');
    };
    const logOutbirthday = () => {
        localStorage.removeItem(`birthday_name`);
        localStorage.removeItem(`birthday_phone`);
        localStorage.removeItem('birthday_token');
        localStorage.removeItem('birthday_bookby');
        navigate('/birthday_login')
    };
    const closeDialog = () => {
        setBookedSlots(0);
        setIsDialogOpen(false);
        setIsDialogOpenn(true);
    };


    const closeDialogg = () => {

        setIsDialogOpenn(false);
    };



    useEffect(() => {
        const getCurrentTime = () => {
            const currentTime = moment().format("LT");
            console.log("fetchAvailableTimeSlots(selectedDate, selectedSlot)", fetchAvailableTimeSlots(selectedDate, selectedSlot));
            const disabledButtonsArray = fetchAvailableTimeSlots(selectedDate, selectedSlot).filter(time => {
                //   console.log(moment(time, "h:mm A").format("LT"));
                //   console.log("currenttime",currentTime);

                const exceedTime = time.split('-')
                const sametime = Array.isArray(exceedTime) ? exceedTime[0] : time;
                const formattedTime = moment(sametime, "h:mm A").format("LT");


                return formattedTime <= currentTime;

            });
            return disabledButtonsArray
        };
        setDisabledButtons(getCurrentTime());

        // Update disabled buttons on mount
        // getCurrentTime();
        console.log(getCurrentTime());
        // Update disabled buttons every minute
        const intervalId = setInterval(() => {
            getCurrentTime();
        }, 60000);

        return () => clearInterval(intervalId);
    }, [selectedSlot])
    // console.log("disabledButtonsArray",disabledButtons);

    useEffect(() => {
        if (selectedDate && selectedTime) {
            fetchBookedSlots();
        }
    }, [selectedDate, selectedTime]);
    // console.log("selectedDate, selectedTime",selectedDate, selectedTime);
    // useEffect(() => {
    //     setCalendar(generateCalendar(currentMonth));
    // }, [currentMonth]);
    useEffect(() => {
        const userRole = localStorage.getItem('birthday_bookby');
        setBirthdayBookby(userRole);
        setShowActionButton(userRole !== 'cultivator');
    }, []);


    const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    useEffect(() => {
        // console.log("www", selectedSlot);
    }, [selectedSlot]);

    useEffect(() => {
        fetchBookedSDateWiselots(selectedDate);
    }, [selectedDate]);

    return (
        <div className="big-container">
            <section className="brithday-sec">
                <div className="row">
                    <div className="calendar-header">
                        {cMonth > new Date() && <button onClick={goToPreviousMonth} className="take-action-button bg-primary">
                            <HiOutlineArrowLeft />
                        </button>
                        }
                        <div className="month-container">
                            <h2>
                                {new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(currentDate)}
                            </h2>
                        </div>
                        <button onClick={goToNextMonth} className="take-action-button bg-primary">
                            <HiOutlineArrowRight />
                        </button>
                        {showActionButton && (
                            <button className="take-action-button" style={{ marginLeft: '5px' }} onClick={handleViewButtonClick}>
                                <HiPencilAlt /> <span>Action</span>
                            </button>
                        )}

                        {birthdayBookby !== 'Bhisma Team' && (
                            <button
                                className="box-hover  text-white p-1"
                                onClick={logOutbirthday}
                                style={{ borderRadius: '2px', border: '2px solid #363434' }}
                            >
                                <span>&nbsp;</span> <HiOutlineLogout /> <span>Logout</span>
                            </button>
                        )}
                    </div>
                    <thead>

                        <tr className="grid-container-days d-none d-lg-grid">
                            {daysOfWeek.map((day, index) => (
                                <th
                                    className={`grid-item-days ${index === 0 ? 'sunday' : ''}`}
                                    style={{
                                        color: index === 0 ? 'red' : 'black',
                                        fontWeight: 'bold'
                                    }}
                                    key={day}
                                >
                                    {day.substring(0, 9)}
                                </th>
                            ))}
                        </tr>

                    </thead>
                    <table className="calendar-table">


                        {Array.from({ length: Math.ceil((firstDay + daysInMonth) / 7) }).map((_, weekIndex) => (
                            <tr key={weekIndex}>
                                {Array.from({ length: 7 }).map((_, dayIndex) => {
                                    const dayNumber = weekIndex * 7 + dayIndex - firstDay + 1;
                                    const calendarDate = dayNumber > 0 && dayNumber <= daysInMonth && moment(`${dayNumber} ${new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(currentDate)}`).format('YYYY-MM-DD');
                                    const viewcalenderDate = `${dayNumber} ${new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(currentDate)}`;
                                    const isDateFuture = calendarDate ? moment(calendarDate).isSameOrAfter(moment(), 'day') : null;
                                    // console.log("isDataFuture", isDateFuture);
                                    const numberOfBookedSlots = allBookedSlots ? allBookedSlots.filter(slot => moment(slot.selectedDate, 'DD-MM-YYYY').date() === dayNumber).length : 0;
                                    const isAllBooked = numberOfBookedSlots >= 21;


                                    return (
                                        <td
                                            key={dayIndex}
                                            className={`calendar-day ${dayNumber > 0 && dayNumber <= daysInMonth ? 'calendar-date' : ''} ${dayIndex === 0 ? 'sunday' : ''
                                                }`}
                                        >
                                            {dayNumber > 0 && dayNumber <= daysInMonth && (
                                                <div
                                                    className={`date-container ${dayIndex === 0 ? 'sunday' : ''} ${isDateFuture ? '' : 'disable-date'} ${dayIndex !== 0 && isAllBooked ? 'all_bookedslot' : ''}`}
                                                    onClick={() => {
                                                        if (dayIndex !== 0 && isDateFuture) {
                                                            handleDateSelection(dayNumber);
                                                            setShowSlots(!showSlots);

                                                        }

                                                    }}
                                                >
                                                    <div className="date-info">
                                                        <div className="date-number">{viewcalenderDate}</div>
                                                        {dayIndex === 0 && <div>Holiday</div>}
                                                        {dayIndex !== 0 && isAllBooked && <div>Already Booked</div>}

                                                    </div>
                                                    {selectedDate === `${dayNumber}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}` && showSlots && (
                                                        <div className="slot-container slot-buttons button">
                                                            {!isAllBooked && (
                                                                <>
                                                                    <button
                                                                        className={`morning-slot ${moment(selectedDate + ' 11:15 AM', 'DD-MM-YYYY h:mm A').isBefore(moment()) ? 'disabled' : ''}`}
                                                                        disabled={moment(selectedDate + ' 11:15 AM', 'DD-MM-YYYY h:mm A').isBefore(moment())} onClick={() => { handleSlotSelection('morning'); fetchBookedSDateWiselots(selectedDate); fetchBookedSlots(selectedDate, selectedTime) }}>
                                                                        Morning Slot
                                                                    </button>
                                                                    <button
                                                                        className={`evening-slot ${moment(selectedDate + ' 05:30 PM', 'DD-MM-YYYY h:mm A').isBefore(moment()) ? 'disabled' : ''}`}

                                                                        disabled={moment(selectedDate + ' 05:30 PM', 'DD-MM-YYYY h:mm A').isBefore(moment())}
                                                                        //  disabled={moment().format('LT')>=moment('05:30PM', "h:mm A").format("LT")} 
                                                                        onClick={() => handleSlotSelection('evening')}>
                                                                        Evening Slot
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                    </table>
                </div>
            </section>




            <Modal centered show={isDialogOpen}>
                <div className="modal-wrap">
                    <button className="btn close-svg" onClick={closeDialog}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                            ></path>
                        </svg>
                    </button>
                    <div className="row">
                        {/* <div className="col-md-5 d-sm-block d-none">
                            <figure>
                                <img
                                    className="w-100"
                                    src={`/images/campaign/feedbackimg.jpeg`}
                                    alt="Campaign image"
                                />
                            </figure>
                        </div> */}
                        <div className="col-md-12">
                            <div className="pop-up-form">

                                <div className="pop-up-top">
                                    <figure className="hare-krishna-logo-fig">
                                        <img src={`/images/campaign/feedbackimg.jpeg`} alt="" />
                                    </figure>
                                    <h3>Birthday Puja Booking Form</h3>


                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>
                                </div>
                                <div className="pop-up-top  col-md-12">
                                    <h6 className='text-center' style={{ color: 'green' }}>Date: {moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')}  Time Slot: {selectedTime}</h6>

                                </div>
                                {bookedSlots === 3 ? 'Already Booked' :
                                    <form
                                        action=""
                                        className="mt-3 mt-sm-0"
                                        onSubmit={handleSubmit(handleBrithdaySave)}
                                    >

                                        <input
                                            type="hidden"
                                            id="selectedSlot"
                                            {...register("selectedSlot")}
                                            value={selectedSlot || ""}
                                        />
                                        <input
                                            type="hidden"
                                            id="selectedDate"
                                            {...register("selectedDate")}
                                            value={selectedDate || ""}
                                        />

                                        <input
                                            type="hidden"
                                            id="selectedTime"
                                            {...register("selectedTime")}
                                            value={selectedTime || ""}
                                        />


                                        <input
                                            type="hidden"
                                            id="poojastatus"
                                            {...register("poojastatus")}
                                            value="Booked"
                                        />

                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="donorname" className="imp">Donor Name</label>
                                                    <input type="text"
                                                        id="donorname"
                                                        {...register("donorname", { required: true })}
                                                        onKeyPress={(event) => {
                                                            if (/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    {errors.donorname?.type === "required" && (
                                                        <span>  Name is required </span>
                                                    )}

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="phone" className="imp">Donor WhatsApp Number</label>
                                                    <input
                                                        type="tel"
                                                        id="phone"
                                                        maxLength={10}

                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register("phone", {
                                                            required: true,
                                                            pattern: {
                                                                value: /^\d+$/,
                                                                message: "Mobile number must be number.",
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: "Phone number must be minimum 10 digit",
                                                            },
                                                        })}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="iscid" className="imp">Donor ICS ID</label>
                                                    <input type="text"
                                                        id="icsid"
                                                        maxLength={6}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...register("icsid")}
                                                    />

                                                </div>
                                            </div>

                                            {/* <div className="col-sm-4">
                                                <div className="label-input-wrap">
                                                    <label htmlFor="whoisbook" className="imp">Booking Team</label>
                                                    <select type="text"
                                                        id="whoisbook" {...register("whoisbook")}>
                                                        <option value="Bhisma Team">BHISMA TEAM</option>
                                                        <option value="Cultivator">Cultivator</option>
                                                    </select>
                                                    {errors.whoisbook?.type === "required" && (
                                                        <span> This is required </span>
                                                    )}
                                                </div>
                                            </div> */}

                                            <div className="col-sm-4">
                                                <div className="label-input-wrap">
                                                    <input type="hidden"
                                                        id="whoisbook"
                                                        value={localStorage.getItem('birthday_bookby') ? localStorage.getItem(`birthday_bookby`) : ''}
                                                        {...register("whoisbook")}
                                                        onKeyPress={(event) => {
                                                            if (/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="label-input-wrap">
                                                    <input type="hidden"
                                                        id="bookername"
                                                        value={localStorage.getItem('birthday_name') ? localStorage.getItem(`birthday_name`) : ''}
                                                        {...register("bookername")}
                                                        onKeyPress={(event) => {
                                                            if (/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />

                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="label-input-wrap">

                                                    <input type="hidden"
                                                        id="bookerphone"
                                                        value={localStorage.getItem('birthday_phone') ? localStorage.getItem(`birthday_phone`) : ''}
                                                        maxLength={10}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}

                                                        {...register("bookerphone", {
                                                            pattern: {
                                                                value: /^\d+$/,
                                                                message: "Mobile number must be number.",
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: "Phone number must be minimum 10 digit",
                                                            },
                                                        })}
                                                    />

                                                </div>
                                            </div>

                                            <button className="box-hover custom-btn" type="submit">Submit Slot</button>



                                        </div>
                                    </form>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal centered show={isDialogOpenn}>
                <div className="modal-wrap">
                    <button className="btn close-svg" onClick={closeDialogg}>
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
                            ></path>
                        </svg>
                    </button>
                    <div className="row">
                        {/* <div className="col-md-5 d-sm-block d-none">
                            <figure>
                                <img
                                    className="w-100"
                                    src={`/images/campaign/feedbackimg.jpeg`}
                                    alt="Campaign image"
                                />
                            </figure>
                        </div> */}
                        <div className="col-md-12">
                            <div className="pop-up-form">

                                <div className="pop-up-top">
                                    <h2>Time Slot</h2>

                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>



                                </div>

                                <div className="container">
                                    <div className="row">
                                        {/* {console.log("selectedSlot", selectedSlot)} */}
                                        {selectedSlot && (
                                            <div className="time-slots">
                                                {/* {console.log("selectedSlot11", selectedSlot)} */}

                                                {Array.isArray(fetchAvailableTimeSlots(selectedDate, selectedSlot)) ?
                                                    fetchAvailableTimeSlots(selectedDate, selectedSlot).map(time => (

                                                        <button
                                                            className={`m-1 pe-2 ps-1 box-hover animation `}
                                                            key={time}
                                                            onClick={() => { handleTimeSelection(time); console.log(isTimeSlotPassed(time)) }}
                                                            disabled={isTimeSlotPassed(time)}

                                                        >

                                                            {
                                                                allBookedSlots?.filter((item) => item.selectedTime === time)?.length >= 3 ?
                                                                    'Not Available' :
                                                                    time

                                                            }
                                                            <br></br>
                                                            <small style={{ color: 'white' }}>Available Slot {(3 - allBookedSlots?.filter((item) => item.selectedTime === time)?.length)}</small>
                                                        </button>

                                                    ))

                                                    :
                                                    <p>No available time slots</p>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default Calendar;
