import React, { useEffect, useState } from 'react'
import { BsWhatsapp } from 'react-icons/bs'

const WhatsAppLink = () => {
  const [show,setShow]=useState(false)
  useEffect(()=>{
    setTimeout(()=>{
      setShow(true)
    },5000)
  },[])
  return (
    <>
    {
      show && 
        <div className='fixed-link'>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=919175263178&text&app_absent=0"> 
                <BsWhatsapp />
            </a>
        </div>
    }
    </>
  )
}

export default WhatsAppLink