import PageHeader from "../components/common/PageHeader"
import { useEffect, useState } from 'react';
import ApiService from '../api/ApiService';
import GoToTop from "../helpers/GoToTop"
import Fancybox from "../helpers/Fancybox"
import Skeleton from "react-loading-skeleton";
import MetaData from "../helpers/MetaData";
import { useLocation } from "react-router-dom";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

const Gallery = () => {

    const location = useLocation()

    const [vCout, setVCount] = useState(9)
    const [images,setImages]=useState([])
    const [showSkeleton,setShowSkeleton]=useState(true)
    const fetchImages=async()=>{
        try{
             const res= await ApiService.fetchGalleryImg()
             setImages(res)
             setShowSkeleton(false)
            }catch(error){
                console.log(error)
            }
    }

    

    useEffect(() => {
        fetchImages()
        if(location.state) {
            setVCount(location.state.vidCount)
        }
      }, [])


      const [meta, setMeta] = useState(null)
      const fetchMeta = async (pagename) => {
          try {
              let res = await ApiService.fetchMetaData(pagename)
              setMeta(res)
          } catch (error) {
              console.log(error)
          }
      }
  
      useEffect(() => {
          fetchMeta('Gallery')
      }, [])

    

  return (
    <>
        <MetaData title="Gallery - ISKCON of Bhiwandi" />
        <PageHeader title="Gallery" imgSrc="/images/page-header/10.webp" />
        <section className="yt-videos">
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-10">
                        <div className="heading  d-flex align-items-center  flex-column">
                            <h2 className="head-1">Gallery </h2>
                            <p className="b-line">Need a writeup</p>
                        </div>
                    </div>
                </div>
                <div className="video-wrap">
                    <div className="row my-5">
                       {
                           showSkeleton?
                           <>
                            {
                                Array.from(Array(vCout), (e,i) => (
                                    <div className="col-lg-4 col-md-6 mb-5" key={i}>
                                        <Skeleton height={230}/>
                                    </div>
                                ))
                            }
                           </>:
                           <>
                              {
                                    images.map((image,i)=>(
                                        <div key={i} className="col-lg-4 col-md-6">
                                            <div className="video-holder">
                                                <Fancybox options={{keyboard: {Escape: "close"}}}>    
                                                    <a data-fancybox="video" href={`${process.env.REACT_APP_S3_URL}/uploads/img-gallery/${image?.image}`} data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/img-gallery/${image?.image}`} >
                                                        <figure className="mb-0 thumbnil">
                                                            <img src={`${process.env.REACT_APP_S3_URL}/uploads/img-gallery/${image?.image}`} alt=""/>
                                                        </figure>
                                                    </a>
                                                </Fancybox>
                                            </div>
                                        </div> 
                                    ))
                        }
                           </>
                       }
                   
                    </div>
                </div>
            </div>
        </section>
        <GoToTop />
    </>

  )
}

export default Gallery