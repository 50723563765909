import { Modal } from 'react-bootstrap'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function ServiceCard(props) {
   const handleModalShow=()=>{
    props.setSelectedSerivce(props.service)

    if(props.service.popup){
        props.setShowModal(true)
    }else{
        if(props.service.redirect ){
            var link = props.service.redirect  ? props.service.link : "/"
            window.open(link, '_blank')

        }else{
            return 
        }
    }
   } 
   return (
    <>
        <div className="book-card">
            <div className="book-top">
                <figure>
                <img src={`${process.env.REACT_APP_S3_URL}/uploads/service/${props.service.image}`} alt="" />
                </figure>
                <h5>{props.service.title.split('|')[0]} {props.service.title.split('|')[1]}</h5>
                <p>
                {props.service?.description}
                </p>
            </div>
            <div className="book-btn">
                <button
                    onClick={handleModalShow}
                    className="box-hover text-uppercase">
                    {props.service?.buttonText}
                </button>
            </div>
        </div>
    </>  
  )
}

export default ServiceCard