import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import "../css/brithdaySlot.css";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import { useForm, Controller } from "react-hook-form";


const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

const FeedbackForm = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [starValueFromBackend, setStarValueFromBackend] = useState(0);
  const { icsid } = useParams();


  useEffect(() => {
    const fetchStarValue = async () => {
      try {
        const response = await ApiService.getStarValue(icsid);
       // console.log(response)
        setStarValueFromBackend(response.starValue);
        if (response.starValue > 0) {
          setFeedbackSubmitted(true);
        }
      } catch (error) {
        console.error("Error fetching starValue:", error);
      }
    };

    fetchStarValue();
  }, [icsid]);

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const updateFeedback = async (feedbackData) => {
    try {
      const dataWithStar = { ...feedbackData, starValue: currentValue };
       //console.log("updatedData", dataWithStar, icsid)

      await ApiService.updateFeedback(icsid, dataWithStar);
      // notification("success", "Hare Krishna! Feedback Form Submitted.");
      reset();
      setFeedbackSubmitted(true);
    } catch (error) {
      console.error("Error updating Feedback data:", error);

    }
  };


  return (
    <div style={styles.container}>

      {/* <h1 style={{ textAlign: "center" }}>Feedback Form for {icsid}</h1> */}
      {feedbackSubmitted ? (
        <div className="heading p-3">
          <h4 className="head-1" style={{ fontSize: '28px' }}>Thank you for your feedback!</h4>
        </div>
      ) : (
        <>
          <div className="heading p-3">
            <h4 className="head-1" style={{ fontSize: '28px' }}>Please share your experience With us</h4>
          </div>


          <form
            action=""
            className="mt-3 mt-sm-0 p-3"
            onSubmit={handleSubmit(updateFeedback)}
            style={{border:'2px solid black', borderRadius:'5px'}}
          >
            <div style={styles.stars}>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <FaStar
                    key={index}
                    size={24}
                    onClick={() => handleClick(index + 1)}
                    onMouseOver={() => handleMouseOver(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    color={
                      (hoverValue || currentValue) > index
                        ? colors.orange
                        : colors.grey
                    }
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                  />
                ))}
            </div>
            <div >
              <label htmlFor="donorFeedback" >Message</label>
              <textarea
                placeholder="What's your experience?"
                style={styles.textarea}
                id="donorFeedback"
                {...register("donorFeedback")}
              />
            </div>
            <button className="box-hover custom-btn" type="submit">
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    marginTop: "200px",
  },
  stars: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: "100%",
    boxSizing: "border-box",
  },
};

const mediaQuery = "@media (max-width: 600px)";
const mobileStyles = {
  container: {
    marginTop: "10px",
  },
};
const mobileContainer = {
  ...styles.container,
  ...mobileStyles.container,
};

export default FeedbackForm;
