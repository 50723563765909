import Banner from "../components/homepage/Banner"
import WelcomeToIskcon from "../components/homepage/WelcomeToIskcon"
import UpcomingImmersions from "../components/homepage/UpcomingImmersions"
import WhyKrishna from "../components/homepage/WhyKrishna.jsx"
import OnDemandCourses from "../components/homepage/OnDemandCourses.jsx"
import Cta from "../components/homepage/Cta.jsx"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import GoToTop from "../helpers/GoToTop"
import MetaData from "../helpers/MetaData"
import ApiService from "../api/ApiService"


const Home = () => {

    const [meta, setMeta] = useState(null)
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMeta('home')
    }, [])

    return (
        <>
            <MetaData title="ISKCON of Bhiwandi — Dissolve in Krishna Consciousness" meta={meta} />
            <Banner />
            <WelcomeToIskcon />
            <UpcomingImmersions />
            <WhyKrishna />
            <OnDemandCourses />
            <Cta />
            <GoToTop />
        </>
    )
}

export default Home