import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import IskconStoryVideo from "../components/about/IskconStoryVideo";
import ApiService from "../api/ApiService";

const AboutUs = () => {

    const [movement, setMovement] = useState(false)
    const [founder, setFounder] = useState(false)
    const [temple, setTemple] = useState(false)

    const founderRef = useRef();
    const templeRef = useRef();
    const missionRef = useRef();
    const location = useLocation()
    const [top, setTop] = useState(0)
    useEffect(() => {
        if(location.hash === '#founder') {
            setTop(founderRef.current.offsetTop)
        }
        if(location.hash === '#temple') {
            setTop(templeRef.current.offsetTop)
        }
        if(location.hash === '#mission') {
            setTop(missionRef.current.offsetTop)
        }
    },[location])
    
    const [meta, setMeta] = useState(null)
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMeta('about-us')
    }, [])

    return (
        <>
            <MetaData title="About Us - ISKCON of Bhiwandi" meta={meta} />
            <PageHeader title="ISKCON of BHIWANDI" imgSrc="/images/page-header/11.webp" />

            <section className="about-heading-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                DISSOLVE IN KRISHNA CONSCIOUSNESS 
                                </h2>
                                <p className="b-line">
                                    Krishna Consciousness is an awareness and an affection for the Supreme Being, Krishna. To be Krishna Conscious is to be a devotee, Bhakt, and be on the path of Bhakti. Bhakti (the path of devotion) states that the highest duty of man is to do that, which generates devotion to Krishna; devotion which is motiveless, which is unyielding to any obstacle and which fills the heart of man with peace.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>

            <section className="mission" ref={missionRef}>
                <div className="container">

                    <div className="mission-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-5 px-xl-5">
                                <figure>
                                    <img src="/images/about-us/krishna.webp" alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-7 mt-lg-0 mt-4">
                                <div className="heading">
                                    <h2 className="head-1 text-lg-start">
                                    THE HARE KRISHNA MOVEMENT
                                    </h2>
                                    <p className="mt-4">
                                    The International Society of Krishna Consciousness (ISKCON) is Bhakti Yoga for today’s age. A global spiritual movement, a mission, to make the world aware of the Supreme Lord, Krishna, and to foster a deep devotion towards Him. ISKCON gently guides you to be a devotee and for this divine devotion to, gradually, come to be your essence even as you continue to be in the world and discharge your worldly duties.
                                    </p>
                                    <p>
                                    The Hare Krishna Movement does not ask of you to renounce anything rather it asks that you add Krishna to your life and in that be Krishna Conscious. The charm lies in the ease of the practise; continue to be in the world even as you cultivate a deep devotion for Krishna. Chant His name as often as you can, hear of His accounts and sing His glories.
                                    </p>
                                    <p>
                                    Hare Krishna devotees chant God’s names in the form of the Mahamantra, “Hare Krishna, Hare Krishna, Krishna Krishna Hare Hare. Hare Rama Hare Rama, Rama Rama, Hare Hare.” The Srimad Bhagvatam states that Krishna, the friend of the devotees, purifies the hearts of those who take His name — that He manifests in their hearts and erases all evil-tendencies. 
                                    </p>
                                    <button onClick={() => setMovement(true) }className="custom-btn-cls box-hover ms-0">
                                        CONTINUE READING
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="founder-info-sec py-lg-0" id="founder" ref={founderRef}>
                <figure className="founder-img">
                    <img src="/images/about-us/prabhu.webp" alt="" />
                </figure>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-1 order-2 mt-4 mt-lg-0">
                            <div className="heading">
                                <div className="h2-wrap">

                                    <h2 className="head-1">
                                        FOUNDER ACHARYA
                                    </h2>
                                    <h2 className="head-2">
                                        SRILA PRABHUPADA
                                    </h2>
                                </div>
                                <p>
                                His Divine Grace A.C. Bhaktivedanta Swami Prabhupada (1896-1977) is widely regarded as the foremost Vedic scholar, translator, and teacher of the modern era. He is especially respected as the world’s most prominent contemporary authority on bhakti-yoga, devotional service to the Supreme Person, SriKrishna, as taught by the ancient Vedic writings of India. He is also the founder-acharya of the International Society for Krishna Consciousness.
                                </p>
                                <p>
                                Srila Prabhupada, as he is known to his followers, translated and commented over eighty volumes of the Vedas’ most important sacred bhakti texts, including the Bhagavad-gita—a concise handbook for understanding the purpose and goal of human life—and the multi-volume Srimad-Bhagavatam—an epic biography of Krishna, Krishna’s avatars, and His many devotees throughout the history of the universe.
                                </p>
                                <button onClick={() => setFounder(true)} className="custom-btn-cls box-hover ms-0">
                                        CONTINUE READING
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 order-lg-2 order-1 d-lg-none d-block">
                            <figure>
                                <img src="/images/about-us/prabhu.webp" alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <IskconStoryVideo 
                imgSrc="/images/about-us/about-thumbnail.webp"
                videoLink="https://www.youtube.com/watch?v=m6Ky4Ky89C4"
                smallText='The story of'
                bigText="ISKCON BHIWANDI"
             />

            <section className="madhav-temple" id="temple" ref={templeRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1 text-uppercase text-center">
                                    sri sri radha madhava temple
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-4 align-items-center">
                        <div className="col-lg-6 pe-xl-5">
                            <div className="temple-content">
                                <p className="mb-lg-4">
                                The magnificent Sri Sri Radha Madhava Temple serves as a centre of Krishna Consciousness in the heart of Bhiwandi, an industrial town on the outskirts of Thane (20kms outside of Mumbai). Our temple is home to Their Lordships Sri Sri Radha Madhava, Sri Sri Jagannath, Baladev and Subhadra and Sri Sri Gaur Nitai and has very quickly become a beacon of Bhakti for the people of Bhiwandi and beyond. With a humble beginning in 2015, it was in the month of Kartik in 2021 that the deities of Sri Sri Radha Madhava were installed on the altar of our temple and Lord Madhava took permanent residence at ISKCON Bhiwandi. 
                                </p>
                                <p>
                                Madhava, the Lord who is sweet as honey and the most-gracious, is here at our temple to accept the love and devotion of His devotees. Our temple is a sanctuary of peace and inner happiness and we offer a host of specific services to help deepen your connection with the Supreme Lord. ISKCON of Bhiwandi is a devotional family and we welcome you to the all-encompassing refuge of Krishna.
                                </p>
                                
                                {/* <button onClick={() => setTemple(true)} className="custom-btn-cls box-hover ms-0">
                                        CONTINUE READING
                                    </button> */}
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <figure className="mb-0 right-img">
                                <img src="/images/about-us/temple-left.webp" alt="" />
                            </figure>
                        </div>
                        <div className="col-12 mt-4">
                            <p className="mb-0">
                            Food for Life - Annadaan, which is the world’s largest vegetarian food relief programme, and among the most important aspects of the Hare Krishna Movement, started at ISKCON Bhiwandi in the year 2020 and in a short span of two years we already distribute a minimum of 1000 wholesome food packets, every day, within a ten-kilometre radius of our temple. 
                            </p>
                            <div className="col-12 mt-4">
                                <figure className="mb-0">
                                    <img src="/images/about-us/temple-bottom.webp" alt="" />
                                </figure>
                            </div>
                            <p className="mt-4">
                            We also have a popular street Harinam programme and a unique Travelling Sankirtan Party, which is a group of 30 devotees who travel all around the country propagating and spreading the nectar of the holy name. Our devotees also conduct seminars in schools, colleges, factories, business centres among others. Daily classes on Bhagavad Gita and Bhagavatam are held inside the temple as also online.
                            </p>
                            <p>
                            We welcome you to the abode of Lord Madhava, come and engage yourself in the services of Krishna. Hare Krishna!
                            </p>                               
                        </div>
                        
                    </div>
                </div>
            </section>
            <Modal contentClassName="about-popup-content" centered show={movement} onHide={() => setMovement(false)}>
                <div className="modal-wrap-read content-modal">
                            <div className="pop-up-form read">
                                <div className="pop-up-top">
                                    <div className="heading">

                                        {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                        <h2 className="head-2">The Hare Krishna Movement </h2>
                                    </div>
                                    <button className="btn close-btn" onClick={() => setMovement(false)}>
                                        <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                                    </button>
                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>
                                </div>
                                <p>
                                The International Society of Krishna Consciousness (ISKCON) is Bhakti Yoga for today’s age. A global spiritual movement, a mission, to make the world aware of the Supreme Lord, Krishna, and to foster a deep devotion towards Him. ISKCON gently guides you to be a devotee and for this divine devotion to, gradually, come to be your essence even as you continue to be in the world and discharge your worldly duties.
                                </p>
                                <p>
                                The Hare Krishna Movement does not ask of you to renounce anything rather it asks that you add Krishna to your life and in that be Krishna Conscious. The charm lies in the ease of the practise; continue to be in the world even as you cultivate a deep devotion for Krishna. Chant His name as often as you can, hear of His accounts and sing His glories.
                                </p>
                                <p>
                                Hare Krishna devotees chant God’s names in the form of the Mahamantra, “Hare Krishna, Hare Krishna, Krishna Krishna Hare Hare. Hare Rama Hare Rama, Rama Rama, Hare Hare.” The Srimad Bhagvatam states that Krishna, the friend of the devotees, purifies the hearts of those who take His name — that He manifests in their hearts and erases all evil-tendencies. 
                                </p>
                                <p>
                                ISKCON is widely acknowledged as the fastest-growing global religious community in the world. From 1966, when His Divine Grace A.C. Bhaktivedanta Swami Prabhupada opened his first temple in New York City, today the movement has grown to be a vibrant community of 600 temples, numerous rural projects and educational institutes, thousands of namahattas or local meeting groups, nearly 100 vegetarian restaurants and millions of congregational members worldwide. 
                                </p>
                                <p>
                                ISKCON belongs to the Gaudiya Vaishnava sampradaya, a monotheistic tradition of Hinduism based on the Bhagavad Gita and the Srimad Bhagavatam. Srila Prabhupada, in giving the world the Hare Krishna Movement, has not only brought the bliss of Krishna to the world but he has also, befittingly, presented Hindu Vedic culture and India’s Vaishnav spirituality to the world.
                                </p>

                            </div>
                </div>
            </Modal>
            <Modal centered show={founder} onHide={() => setFounder(false)}>
                <div className="modal-wrap-read content-modal">
                            <div className="pop-up-form read">
                                <div className="pop-up-top">
                                    <div className="heading">

                                        {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                        <h2 className="head-2">Founder </h2>
                                    </div>
                                    <button className="btn close-btn" onClick={() => setFounder(false)}>
                                        <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                                    </button>
                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>
                                </div>
                                <p>
                                His Divine Grace A.C. Bhaktivedanta Swami Prabhupada (1896-1977) is widely regarded as the foremost Vedic scholar, translator and teacher of the modern era. He is especially respected as the most-prominent contemporary authority on Bhakti Yoga both for his devotional service to Sri Krishna and also for making devotion, or Krishna Consciousness, a path for others to walk on. This, by way of the International Society for Krishna Consciousness (ISKCON), popularly known as the Hare Krishna Movement, of which he is the Founder Acharya. 
                                </p>
                                <p>
                                In 1965, at age sixty-nine, with forty rupees and a trunk of his Bhagavatam commentaries – the first ever in English – he made his way to New York with but one aim, “introduce India’s message of peace and goodwill” to the western world. Only a year later, in 1966, he established ISKCON by way of a humble store-front presence in New York and thereon went on to inspire thousands to devote their lives to Krishna consciousness, launching one of the fastest-growing spiritual movements in the history of the world. 
                                </p>
                                <p>
                                Men and women from all backgrounds came forward and with their help, Srila Prabhupad, as he is known by his followers, established temples, farm communities, a publishing house and educational institutions around the world. Simultaneously he began what has now become the world’s largest vegetarian food relief programme, Food for Life, AnnaDaan.
                                </p>
                                <p>
                                With the desire to nourish the roots of Krishna Consciousness in it’s home, Srila Prabhupada returned to India several times, where he sparked a revival in the Vaishnav tradition. In India, he opened dozens of temples, including large centres in the holy towns of Vrindavan and Mayapur.
                                </p>
                                <p>
                                Srila Prabhupad’s most significant contributions, perhaps, are his books. He authored over 70 volumes on the Krishna tradition, which are highly respected by scholars for their authority, depth and clarity. Several of his works are used as textbooks in numerous college courses. His writings have been translated into 76 languages. His most prominent works include: Bhagavad Gita As It Is, the 30-volume Srimad-Bhagavatam, and the 17-volume Sri Caitanya Caritamrita.
                                </p>
                                <p>
                                Srila Prabhupada passed away on November 14, 1977, in the holy town of Vrindavan, surrounded by his loving disciples who carry on his mission today. 
                                </p>
                                <p>
                                For more information on our beloved Founder Acharya, please <a href="http://www.founderacharya.com/" target="_blank">Click here</a>
                                </p>
                            </div>
                </div>
            </Modal>
            <Modal centered show={temple} onHide={() => setTemple(false)}>
                <div className="modal-wrap-read content-modal">
                            <div className="pop-up-form read">
                                <div className="pop-up-top">
                                    <div className="heading">

                                        {/* <h2 className="head-2">BOOK A KIRTAN</h2> */}
                                        <h2 className="head-2">Sri Sri Radha Madhava Temple</h2>
                                    </div>
                                    <button className="btn close-btn" onClick={() => setTemple(false)}>
                                        <svg className="close-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                                    </button>
                                    <figure className="hare-krishna-logo-fig">
                                        <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                                    </figure>
                                </div>
                            

                            </div>
                </div>
            </Modal>
            <GoToTop top={top} />
        </>
    );
}

export default AboutUs;