import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Campaign from "./Campaign";
import ApiService from "../api/ApiService";
import Home from "./Home";
import PageNotFound from "./PageNotFound";
import Donate from "./Donate";
import Festivals from "./Festivals";
import Movement from "./Movement";
import Services from "./Services";
import Yatras from "./Yatras";
import Volunteer from "./Volunteer";
import FoodForLife from "./FoodForLife";
import Contact from "./Connect";
import AboutUs from "./AboutUs";
import GaudiaPage from "./GaudiaPage";
import Courses from "./Courses";
import SecretOfSuccess from "./SecretOfSuccess";
import BhagavadGita from "./BhagavadGita";
import LifeMembership from "./LifeMembership";
import JoinFamily from "./JoinFamily";
import CampaignDonationForm from "./CampaignDonationForm";
import LiveVideo from "./LiveVideo";
import ShopList from "./ShopList";
import CsrNew from "./CsrNew";
import RecentEvents from "./RecentEvents";
import DailyDarshan from "./DailyDarshan";
import EventDetails from "./Event-Details";
import NityaSeva from "./NityaSeva";
import Gallery from "./Gallery";
import ClassesVideo from "./ClassesVideo";
import DonationForm from "./DonationForm";
import Campaigns from "./Campaigns";
import LoaderScreen from "../components/common/LoaderScreen";

const DynamicPage = () => {
  const { slug } = useParams();
  const [trackingCampaign, setTrackingCampaign] = useState(null);
  const [isTrackingCampaign, setIsTrackingCampaign] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

const fetchCampaign = async () => {
    try {
      let { campaign, isTrackingCampaign, trackingCampaign } = await ApiService.fetchCampaign(
        slug
      );

      if (campaign) {
        if (isTrackingCampaign) {
          setIsTrackingCampaign(isTrackingCampaign)
          setTrackingCampaign(trackingCampaign);
          localStorage.setItem("tracking_campaign", campaign?._id);
        } else {
          setCampaign(campaign);
        }
      } else {
        navigate("/404");
      }
    } catch (error) {
      console.log(error);
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaign()
  }, [slug]);

  if(loading) {
    return <LoaderScreen />
  }

  if(!trackingCampaign?.trackingType || trackingCampaign?.trackingType === 'campaign' || (campaign && campaign.campaign)) {
    return <Campaign customSlug={slug} />
  }

  if(campaign && !isTrackingCampaign) {
    if(campaign.slug === slug) {
        return <Campaign customSlug={slug} />
    }
  }

  if(trackingCampaign?.anyLinkUrl, `${process.env.REACT_APP_PROJECT_URL}/donate`) {
      switch(trackingCampaign?.anyLinkUrl) {
        case `${process.env.REACT_APP_PROJECT_URL}` : {
            return <Home />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/donate-categories` : {
            return <Donate />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/donate` : {
            return <Campaigns />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/festivals` : {
            return <Festivals />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/movement` : {
            return <Movement />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/services` : {
            return <Services />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/yatras` : {
            return <Yatras />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/volunteer` : {
            return <Volunteer />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/food-for-life` : {
            return <FoodForLife />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/contact` : {
            return <Contact />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/about-us` : {
            return <AboutUs />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/gaudia-vaishnavism` : {
            return <GaudiaPage />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/courses` : {
            return <Courses />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/secret-of-success` : {
            return <SecretOfSuccess />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/bhagavad-gita` : {
            return <BhagavadGita />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/life-membership` : {
            return <LifeMembership />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/join-family` : {
            return <JoinFamily />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/campaign-donation-form` : {
            return <CampaignDonationForm />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/live-video` : {
            return <LiveVideo />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/shop-list` : {
            return <ShopList />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/csr-new` : {
            return <CsrNew />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/recent-events` : {
            return <RecentEvents />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/daily-darshan` : {
            return <DailyDarshan />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/event-details` : {
            return <EventDetails />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/nitya-seva` : {
            return <NityaSeva />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/gallery` : {
            return <Gallery />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/classes-video` : {
            return <ClassesVideo />
        }
        case `${process.env.REACT_APP_PROJECT_URL}/donation-form` : {
            return <DonationForm isTrackingCampaign={true} />
        }
        default: {
            return <PageNotFound />
        }
      }
  }

};

export default DynamicPage;
