export const schemeName = [
    {
      id: 1,
      schemename: "A General Donation",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
  
    {
      id: 2,
      schemename: "Support Deity Worship",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 3,
      schemename: "Tulsi Worship",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 4,
      schemename: "Annadaan",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 5,
      schemename: "Sponsor Deity Bhoga",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 6,
      schemename: "Sponsor Sankirtan",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
  
    {
      id: 7,
      schemename: "Sponsor Book Distribution",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
  
    {
      id: 8,
      schemename: "Offer a puja in your name",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 9,
      schemename: "Sponsor Aarti",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 10,
      schemename: "Sadhu Bhojan",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 11,
      schemename: "Ekadashi",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
    {
      id: 12,
      schemename: "Flower Seva",
      amount: "amount",
      interval: "interval",
      period: "period",
    },
  ];