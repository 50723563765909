import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const GoToTop = ({ top = 0 }) => {
  const routePath = useLocation();
  const timeoutRef = useRef(null);

  const onTop = (newTop) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      window.scrollTo(0, newTop);
    }, 300);
  };

  useEffect(() => {
    onTop(top);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [routePath, top]);

  return null;
};

export default GoToTop;
